
import { defineComponent, watch, ref } from 'vue'
import RouteTabs from '@/views/Payment/components/RouteTabs.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    RouteTabs
  },
  setup() {
    const router = useRouter()
    const isQuery = ref<boolean>(true)
    watch(() => router.currentRoute.value.path, (newValue, oldValue) => {
      if (newValue.includes('query')) {
        isQuery.value = true
      } else {
        isQuery.value = false
      }
    },{ immediate: true })

    return {
      isQuery
    }
  }
})
